@import '../../node_modules/@oforce/global-styles/src/styles/custom';

// Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

@import '../../node_modules/@oforce/global-styles/src/styles/index';

.page-content {
  @extend .flex-fill, .bg-gray-200;
  min-height: calc(100vh - 41px);
  max-height: 100vh;
  overflow-y: scroll;

  @include media-breakpoint-down(sm) {
    padding-top: 64px !important;
    padding-bottom: 55px !important;
  }
}
